/* @font-face {
  font-family: "CharlieDisplay";
  src: url("./assets/fonts/CharlieDisplay/CharlieDisplay-Thin.otf");
  font-weight: 100;
} */

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-size: 14px;
  font-weight: normal;
  color: #172b4d;
}

html,
body,
#root {
  height: 100%;
  background-color: #f7f6f3;
}

h3 {
  font-size: 1rem;
}

body {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-container.toast__container,
.Toastify__toast.toast__toast,
.Toastify__toast-body.toast__body {
  padding: 0px;
  margin: auto;
  border-radius: 3px;
}

.Toastify__toast-container.toast__container {
  width: 100%;
  max-width: 400px;
  top: 3em;
  left: 0;
  right: 0;
}

.Toastify__toast.toast__toast {
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px 0 rgba(69, 69, 69, 0.2);
}

.Toastify__toast.toast__toast {
  min-height: auto;
}

@media only screen and (max-width: 512px) {
  .Toastify__toast-container.toast__container {
    width: 100%;
    max-width: 400px;
    top: 10px;
    left: 0;
    right: 0;
  }
}
